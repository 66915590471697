import { Injectable } from '@angular/core';
import { Equipment } from './equipment.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  list: Equipment[];
  selectedEquipment: Equipment;
  readonly rootURL = environment.apiGwUrl+"/Objects"
  readonly equipmentManagerURL = environment.apiGwUrl+"/equipmentManager"
  readonly displayOrderManager = environment.apiGwUrl + "/displayorderManager"

  constructor(private http: HttpClient) { }

  refreshList(id: number) {
    this.http.get(this.rootURL + "?type=equipment&plantName=" + id + "&lineId=")
      .toPromise().then(res => this.list = res as Equipment[]);
  }

  aSynchRefreshList(id: number) {
    return this.http.get(this.rootURL + "?type=equipment&plantId=" + id + "&lineId=");
  }

  setSelectedEquipment(equipment: Equipment) {
    this.selectedEquipment = equipment;
  }

  postEquipment(formData: Equipment) {
    return this.http.post(this.equipmentManagerURL + '?type=create', formData);
  }

  putEquipment(formData: Equipment) {
    return this.http.post(this.equipmentManagerURL + '?type=update', formData);

  }

  deleteEquipment(id: number) {
    return this.http.post(this.equipmentManagerURL + '?type=delete&id=', { id: id });
  }
  sortEquipment(obj: any){
    console.log("data obj",obj);
    return this.http.post(this.displayOrderManager, obj);
  }

}
