import { Component,Inject } from '@angular/core';
import {GEA_MES_NAVBAR_MENU_SERVICE} from '@gea-mes/navbar';
import {NavItemService,NavItem} from './nav-item.service';
import { BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'brilliant-config';

  objectModel = {};

constructor(public navService:NavItemService,
  @Inject(GEA_MES_NAVBAR_MENU_SERVICE) private NavItem: BehaviorSubject<NavItem[]>) {
    NavItem.next(navService.navItems);
   }

  ngOnInit() {

  }
}
