<mat-card class="log-out-card">
    <mat-card-header>
        <mat-card-title class="loginHeader">You are Logged Out</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class="loginMessage">
            You have been logged out. If you need to log back in, click the button below.
        </p>
        <p class="login">
            If you are not using the application be sure to close your browser window.
        </p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" class="loginButton" (click)="logIn()">Log In</button>
    </mat-card-actions>
</mat-card>