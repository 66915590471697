// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiGateway: {
    url: "https://api-sysval.sc.geappl.io/",
    stage: "brilliantconfig"
  },    
  apiGwUrl: "https://api-sysval.sc.geappl.io/brilliantconfig",
  cognitoOptions: {
    ClientId: '6momgtan28o12pevla32ug4pet', // Your client id here
    AppWebDomain: 'mesauth.auth.us-east-1.amazoncognito.com', // Exclude the "https://" part.
    TokenScopesArray: ['openid', 'profile', 'email'],
    UserPoolId: 'us-east-1_s1pKZcsUG',
  },
  AllowScreenAuth: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
