<form [formGroup]="plantControl">
  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="compareFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{plantControl.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>
  <a mat-raised-button color="primary" class="newButton" (click)="createEquipment()" [disabled]=!this._global.admin >Add New Equipment</a>
</form>



<div class="table-padding">
    <table class="lineTable example-list" id="table" cdkDropList (cdkDropListDropped)="drop($event)">
        <tr>
          <th width="16%">Name</th>
          <th width="16%">Object</th>
          <th width="16%">eAM Asset Name</th>
          <th width="16%">Display Order</th>
          <th width="16%">Group</th>
        </tr>

        <tr *ngFor="let equipment of equipmentService.list" style="cursor:pointer" class="example-box" cdkDrag>
          <td width="16%" (click)="onEquipmentSelect(equipment)">{{equipment.name}}</td>
          <td width="16%" (click)="onEquipmentSelect(equipment)">{{equipment.object}} </td>
          <td width="16%" (click)="onEquipmentSelect(equipment)" >{{equipment.asset_name}}</td>
          <td width="16%" (click)="onEquipmentSelect(equipment)">{{equipment.display_order}}</td>
          <td width="16%" (click)="onEquipmentSelect(equipment)">{{equipment.group}}</td>
          <td width="5%"><button mat-raised-button (click)="openDialog(equipment)" color="warn" [disabled]=!this._global.admin >X</button></td>
        </tr>

      </table>

</div>
