import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'src/app/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { PlantService } from '../plant.service';
import { DeltaService } from './delta.service';
import { ConfigColumn} from './configColumn.model';
import { ConfigValue} from './configValue.model';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { Globals } from '../../app.globals';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import {first } from 'rxjs/operators';

@Component({
  selector: 'app-delta',
  templateUrl: './delta.component.html',
  styleUrls: ['./delta.component.css']
})
export class DeltaComponent implements OnInit {
  plantControl: UntypedFormGroup;
  deltaNameContrl: UntypedFormGroup;
  valueTableHeader:any=[];
  subAsssemblyDeltaData:any=[];
  showTable:boolean=false;
  tempObj:any={};
  update:any = [];
  subAsseblySortedData:any ={};
  activeTabIndex:number=0;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  columnConfigDisbale:boolean=true;
  valueConfigDisbale:boolean=true;
  deltaButtonName:string;
  dynamicButtonTableShow:boolean=false;
  statusCode:any;
  adminSub$: Subscription;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public plantService: PlantService,
    public deltaservice: DeltaService,
    public _global: Globals,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService

  ) { }

  ngOnInit() {

    this.plantControl = this.fb.group({
      plantControl: [null, Validators.required]
    });

    this.deltaNameContrl=this.fb.group({
      deltaName:[null]
    });

    if (this.plantService.selectedPlant != undefined) {
      this.plantService.aSynchRefreshList().subscribe(data => {
        this.plantControl.get('plantControl').setValue(this.plantService.selectedPlant);
        this.getData();
        this.activeTabIndex=this.deltaservice.lastActivatedTab;
      });
    } else {
      this.plantService.refreshList();
    }

   this.deltaservice.selectedColumnConfig=undefined;
   this.deltaservice.selectedValueConfig=undefined;

   this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
    this._global.admin = out;
    if (this.adminSub$) this.adminSub$.unsubscribe();
  });

  }


  onPlantSelect() {
    this.plantService.setSelectedPlant(this.plantControl.get('plantControl').value);
    this.getData();
    this.plantControl.get('plantControl').setValue(this.plantControl.get('plantControl').value)
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  formatData(data){
    let valueData=data[0].SubFreeFormConfig;
    let coulmnData=data[0].SubFreeFormValue;
    if(coulmnData.length==5){
      this.columnConfigDisbale=true;
    }else{
      this.columnConfigDisbale=false;
    }
    if(coulmnData.length>0){
      this.valueConfigDisbale=false;
    }else{
      this.valueConfigDisbale=true;
    }
    this.valueTableHeader=[];
    if(coulmnData.length>0){
      this.valueTableHeader.push('Name');
      this.valueTableHeader.push('Display Name');
      this.valueTableHeader.push('Display Order');
      coulmnData.forEach(element => {
        this.valueTableHeader.push(element.name);
      });
      this.valueTableHeader.push('Rollup');
      this.showTable=true;
    }else{
      this.showTable=false;
      this._snackBar.open("No Data", "", {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
    }

  }

  onColumnConfigsSelect(columnconfig: ConfigColumn) {
    this.deltaservice.setSelectedColumnConfig(columnconfig);
    if (this._global.admin) {
      this.router.navigate(["/update-columnConfig"]);
    }
    else {
      this.notAuthorizedMessage();
    }
  }

  createColumnConfig() {
    if (this._global.admin) {
      this.router.navigate(["/create-columnConfig"]);
    }
  }

  onValueConfigsSelect(valueconfig: ConfigValue) {
    this.deltaservice.setSelectedValueConfig(valueconfig);
    if (this._global.admin) {
      this.router.navigate(["/update-valueConfig"]);
    }
    else {
      this.notAuthorizedMessage();
    }
  }

  deleteColumnConfig(row, index) {
    if (this._global.admin) {
      let dbColumnIndex = index + 1;
      let dbColumnName = "C" + dbColumnIndex + "Value";
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          row.type = "delete";
          var checkValueConfig = this.CheckForValueConfig(this.subAsssemblyDeltaData[0].SubFreeFormConfig, dbColumnName);
          console.log("check check:", checkValueConfig);

          if (checkValueConfig) {
            this._snackBar.open("Please delete the values from value config before deleting column config.", "Faiure", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          } else {
            const a = document.createElement('a');
            a.click();
            a.remove();
            this.deltaservice.deleteColumnConfig(row).subscribe(res => {
              if (res['Body']['RecordCount'] > 0) {
                this.showSuccessMessage();
                this.getData();
              } else {
                this.showFailureMessage();
              }
            });
          }

        }
      });
    }

  }

  deleteValueConfig(row) {
    if (this._global.admin) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          row.type = "delete";
          this.deltaservice.deleteValueConfig(row).subscribe(res => {
            if (res['Body']['RecordCount'] > 0) {
              this.showSuccessMessage();
              this.getData();
            } else {
              this.showFailureMessage();
            }
          });
        }
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.update=[];
    this.subAsseblySortedData={};
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTabIndex=tabChangeEvent.index;
    this.deltaservice.storeActiveTab(this.activeTabIndex);
  }

  showSuccessMessage(){

    this._snackBar.open("Record Deletion Success", " ", {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['alert-success'],
    });

  }

  showFailureMessage(){
      this._snackBar.open("Record Deletion", "Failure", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
     }

  getData(){
    this.dynamicButtonTableShow=false;
    this.deltaservice.getSubAssemblyDelta(this.plantControl.get('plantControl').value.name).subscribe(res => {
      this.subAsssemblyDeltaData=res;
      this.dynamicButtonTableShow=true;
      if(res[0].ButtonName[0]){
        this.deltaButtonName=res[0].ButtonName[0].button_name;
      }else{
        this.deltaButtonName="";
      }

      this.deltaNameContrl.get('deltaName').setValue(this.deltaButtonName);
      this.formatData(res);
      this.deltaservice.storeSubAssemblyColumnData(res[0].SubFreeFormValue);
    });
  }

  addNewValueConfig() {
    if (this._global.admin) {
      if (this.subAsssemblyDeltaData[0].SubFreeFormValue.length > 0) {
        this.router.navigateByUrl("/create-valueConfig");
      }
      else {
        this._snackBar.open("Please Configure Column(s) first", "", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      }
    }
  }

  CheckForValueConfig(valueData,columnName){
    let hasValues=false;
    if(valueData.length>0){
      var str=valueData[0][columnName];
      for(var i=0;i<valueData.length;i++){
        if(valueData[i][columnName].replace(/ /g, "")!=""){
          hasValues=true;
        }
      }
    }
   return hasValues;
  }

  nameConfigSubmit(data?: any) {
    if (this._global.admin) {
      let jsonData = {};
      if (this.deltaNameContrl.get('deltaName').value.trim() == "") {
        this._snackBar.open("Please Enter Sub-Assembly Delta's Name", "", {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });
      } else {
        jsonData['plant_name'] = this.plantControl.get('plantControl').value.name;
        jsonData['button_name'] = this.deltaNameContrl.get('deltaName').value.trim();
        this.deltaservice.updateDeltaButtonName(jsonData).subscribe(resp => {
          if (resp['Body'].RecordCount >= 0) {
            this._snackBar.open("Record Updated Succesfully", "", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['alert-success'],
            });
          } else {
            this._snackBar.open("Record Updation", "Failure", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          }
        });
      }
    }
  }

  notAuthorizedMessage(){
    this._snackBar.open("USER NOT AUTHORISED", "OK", {
      duration: 0,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
