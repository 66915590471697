import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, NgForm } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PlantService } from 'src/app/plant/plant.service';
import { LineService } from '../../../line.service';
import { SublineService } from '../subline.service';
import { Subline } from '../subline.model';
import {Router} from "@angular/router";
@Component({
  selector: 'app-subline-manager',
  templateUrl: './subline-manager.component.html',
  styleUrls: ['./subline-manager.component.css']
})
export class SublineManagerComponent implements OnInit {

  formGroup: UntypedFormGroup;
  id: number;
  type: boolean = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  comparePlantFn: ((f1: any, f2: any) => boolean) | null = this.compareByPlantValue;
  compareLineFn: ((f1: any, f2: any) => boolean) | null = this.compareByLineValue;
  splant: any =[];
  projectlist: any=[{}];
  Selectsubline: any=[];
  compareprojectFn: ((f1: any, f2: any) => boolean) | null = this.compareprojectFnValue;

  constructor(private formBuilder: UntypedFormBuilder,
    public sublineService: SublineService,
    public plantService: PlantService,
    public lineService: LineService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router) {

    //Initialize form
    this.formGroup = this.formBuilder.group({
      id: [],
      name: [],
      plant_id: [],
      line_id: [],
      status: [],
      target: [],
      starts: [],
      packed: [],
      display_order: [],
      plantControl: [],
      lineControl: [],
      asset_name:[],
      projectControlUpdate:[]
    });

  }

  ngOnInit() {
    this.route.data.subscribe(v => {
      if (v.type == 'create') {
        this.type = true;
        this.formGroup.get('plantControl').enable();
        this.formGroup.get('lineControl').enable();
      } else {
        this.type = false;
        this.formGroup.get('plantControl').disable();
        this.formGroup.get('lineControl').disable();
     
        
      }

    });
    //set selected plant
   // this.plantService.aSynchRefreshList().subscribe(data => {
      if (this.plantService.selectedPlant != undefined) {
        console.log("this.plantService.selectedPlant::",this.plantService.selectedPlant);
        console.log("this.lineService.selectedline:",this.lineService.selectedline)
        this.plantService.aSynchRefreshList().subscribe(data => {
        this.formGroup.get('plantControl').setValue(this.plantService.selectedPlant);
        this.formGroup.get('plant_id').setValue(this.plantService.selectedPlant.id);
       
        let plantSelcted=this.formGroup.get('line_id').value;
        console.log("plantSelcted::",plantSelcted);
        this.splant=this.plantService.plantSeclet; 
        console.log("this.splant::",this.splant);
        this.plantService.refreshProjectList(this.splant).subscribe(data => {
        this.projectlist=data;
        console.log("this.projectlist::",this.projectlist)
        console.log("this.equpData::",this.projectlist);
        this.Selectsubline= this.projectlist.find(e => e.id ==  plantSelcted);
        console.log("Selectsubline::",this.Selectsubline)
       // let finaltext=this.Selectsubline.name+"-"+this.Selectsubline.Environment;
       // this.formGroup.get('projectControlUpdate').setValue(finaltext);
       
        })

      });
      
      }

   
      if (this.lineService.selectedline != undefined && this.plantService.selectedPlant != undefined) {
        this.lineService.aSynchRefreshList(this.plantService.selectedPlant.id).subscribe(data => {
          this.formGroup.get('lineControl').setValue(this.lineService.selectedline);
          this.formGroup.get('line_id').setValue(this.lineService.selectedline.id);
        });
      }
   // });

    //set selected line
    if (this.sublineService.selectedSubline != undefined) {
      this.formGroup.patchValue(this.sublineService.selectedSubline);
      this.sublineService.selectedSubline = null;
    }
  }
  compareprojectFnValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
  onPlantSelect() {
    this.formGroup.get('plant_id').setValue(this.formGroup.get('plantControl').value.id);
    this.plantService.setSelectedPlant(this.formGroup.get('plantControl').value);
    this.lineService.refreshList(this.formGroup.get('plantControl').value.name);
  }

  onLineSelect() {
    this.lineService.setSelectedline(this.formGroup.get('lineControl').value);
    this.formGroup.get('line_id').setValue(this.formGroup.get('lineControl').value.id);
  }
  compareByPlantValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  compareByLineValue(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id;
  }

  onFormSubmit() {
    if (this.formGroup.getRawValue().id == null) {
      this.insertRecord(this.formGroup.getRawValue());
      console.log('insert');
    } else {
      this.updateRecord(this.formGroup.getRawValue());
      console.log('update');
    }
   
  }

  insertRecord(form: Subline) {
    this.sublineService.postSubline(form).subscribe(res => {
      console.log(res);
      this._snackBar.open("Record creation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
      this.router.navigate(["/sublines"]);
    });
  }

  updateRecord(form: Subline) {
    this.sublineService.putSubline(form).subscribe(res => {
      console.log(res);
      this._snackBar.open("Record Updation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
     
      this.sublineService.refreshList(
        this.formGroup.get('plantControl').value.id,
        this.formGroup.get('lineControl').value.id,
      );
     
    });
    this.router.navigate(["/sublines"]);
    
  }


}
