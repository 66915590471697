import { Component, OnInit } from '@angular/core';
import { PlantService } from './plant.service';
import { Plant } from './plant.model';
import { Router } from '@angular/router';
import { ConfirmationDialog } from '../confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { Globals } from '../app.globals';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import {first } from 'rxjs/operators';

@Component({
  selector: 'app-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.css']
})
export class PlantComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  statusCode:any;
  adminSub$: Subscription;

  constructor(public plantService: PlantService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public _global: Globals,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService) { }

  ngOnInit() {
    this.plantService.refreshListData();

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
  }

  onPlantSelect(plant: Plant) {
    this.plantService.setSelectedPlant(plant);
    if (this._global.admin) {
      this.router.navigate(["/update-plant"]);
    }
    else {
      this.notAuthorizedMessage();
    }

  }

  createPlant() {
    if (this._global.admin) {
      this.router.navigate(["/create-plant"]);
    }

  }

  notAuthorizedMessage(){
    this._snackBar.open("USER NOT AUTHORISED", "OK", {
      duration: 0,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
