<h1 style="text-align:center;color:#3f51b5" *ngIf="type">Create Line</h1>
<h1 style="text-align:center;color:#3f51b5" *ngIf="!type">Update Line</h1>


<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="compareFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{formGroup.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>

  <ng-container *ngIf="type">
    <mat-form-field class="slicer">
      <mat-label>Project</mat-label>
      <mat-select formControlName="projectControl" required >
        <mat-option *ngFor="let linedata of projectlist" [value]="linedata">
          {{linedata.name}} - {{linedata.project_sc}}
        </mat-option>
      </mat-select>
  
      <mat-hint>{{formGroup.get('plantControl').value?.display_name}}</mat-hint>
    </mat-form-field> 
  
  </ng-container>

  <ng-container *ngIf="!type">
    <mat-form-field class="slicer">
      <mat-label>Project</mat-label>
      <mat-select formControlName="projectControlUpdate" disabled required [compareWith]="compareFn">
        <mat-option  [value]="Selectline">
          {{Selectline.name}} - {{Selectline.project_sc}}
        </mat-option>
      </mat-select>
  
      <mat-hint>{{formGroup.get('projectControlUpdate').value?.display_name}}</mat-hint>
    </mat-form-field> 
  
  </ng-container>

  <div class="parent">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="plant_id">
    <table class="lineTable">

    
      <tr>
        <td >
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Line Name</mat-label>
            <input *ngIf="!type" [errorStateMatcher]="matcher" formControlName="name" matInput placeholder="Line Name" pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)" required>
            <input *ngIf="type" formControlName="name" matInput placeholder="Line Name" pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)" required>
            <mat-hint style="margin-left: -10px;">Line Name supports A-Z,a-z,0-9</mat-hint>
          <mat-error>
            <span *ngIf="formGroup.get('name').hasError('required') && !formGroup.get('name').valid
            ">Please Enter Line Name</span>
            <span *ngIf=" !formGroup.get('name').hasError('required') && !formGroup.get('name').valid">Invalid Line Name. Supports only A-Z,a-z,0-9</span>
          </mat-error>
        </mat-form-field>
        </td>

        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Order</mat-label>
            <input formControlName="display_order" matInput placeholder="Display Order">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('display_order').valid && formGroup.get('display_order').touched">Please enter Display Order !!!</span>
          </mat-error>
        </td>

      </tr>
      
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Schedule Area</mat-label>
            <input formControlName="schedule_area" matInput placeholder="Schedule Area">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('schedule_area').valid && formGroup.get('schedule_area').touched">Please enter Schedule Area !!!</span>
          </mat-error>
        </td>

        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Schema</mat-label>
            <input formControlName="SchemaName" matInput placeholder="Test Schema">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('SchemaName').valid && formGroup.get('SchemaName').touched">Please enter Test Schema!!!</span>
          </mat-error>
        </td>
     
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Line Code</mat-label>
            <input formControlName="LineCode" matInput placeholder="Line Code">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('LineCode').valid && formGroup.get('LineCode').touched">Please enter Line Code !!!</span>
          </mat-error>
        </td>
        
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Line</mat-label>
            <input formControlName="test_line" matInput placeholder="Test Line">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('test_line').valid && formGroup.get('test_line').touched">Please enter Test Line !!!</span>
          </mat-error>
        </td>

      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Status</mat-label>
            <input formControlName="status" matInput placeholder="Status">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('status').valid && formGroup.get('status').touched">Please enter Status !!!</span>
          </mat-error>
        </td>
        
        <td >
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Line Description</mat-label>
            <input formControlName="Description" matInput placeholder="Test Line Description">
          </mat-form-field>
        </td>

      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Target</mat-label>
            <input formControlName="target" matInput placeholder="Target">
          </mat-form-field>            
          <mat-error>
            <span *ngIf="!formGroup.get('target').valid && formGroup.get('target').touched">Please Enter Target</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Station</mat-label>
            <input formControlName="test_station" matInput placeholder="Test Station">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('test_station').valid && formGroup.get('test_station').touched">Please enter Test Station!!!</span>
          </mat-error>
        </td>

      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Starts</mat-label>
            <input formControlName="starts" matInput placeholder="Starts">            
          </mat-form-field>            
          <mat-error>
            <span *ngIf="!formGroup.get('starts').valid && formGroup.get('starts').touched">Please Enter Starts</span>
          </mat-error>

        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Area</mat-label>
            <input formControlName="test_area" matInput placeholder="Test Area">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('test_area').valid && formGroup.get('test_area').touched">Please enter Test Area !!!</span>
          </mat-error>
        </td>

      </tr>

      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Packed</mat-label>
            <input formControlName="packed" matInput placeholder="Packed">
          </mat-form-field>            
          <mat-error>
            <span *ngIf="!formGroup.get('packed').valid && formGroup.get('packed').touched">Please Enter Packed</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Code</mat-label>
            <input formControlName="test_code" matInput placeholder="Test Code">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('test_code').valid && formGroup.get('test_code').touched">Please enter Test Code!!!</span>
          </mat-error>
        </td>
      </tr>
     <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>EOLA Schema</mat-label>
            <input formControlName="eola_schema" matInput placeholder="Eola Schema">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('eola_schema').valid && formGroup.get('eola_schema').touched">Please enter Eola Schema!!!</span>
          </mat-error>
        </td>
       <td>
            <mat-form-field class="demo-full-width" appearance="outline">
              <mat-label>FPY Target</mat-label>
              <input *ngIf="!type" [errorStateMatcher]="matcher" formControlName="fpy_quality_threshold" pattern="^[0-9]*$" (keypress)="restrictSpecialCharachters($event)"
              matInput placeholder="Fpy Target" required>
              <input *ngIf="type" formControlName="fpy_quality_threshold" pattern="^[0-9]*$" (keypress)="restrictSpecialCharachters($event)"
              matInput placeholder="Fpy Target" required>
              <mat-hint style="margin-left: -10px;">FPY Target supports numeric only</mat-hint>
              <mat-error>
                <span *ngIf="formGroup.get('fpy_quality_threshold').hasError('required') && !formGroup.get('fpy_quality_threshold').valid">Please Enter FPY Target</span>
                <span *ngIf=" !formGroup.get('fpy_quality_threshold').hasError('required') && !formGroup.get('fpy_quality_threshold').valid">Invalid FPY Target. Supports numeric only.</span>
            </mat-error>
            </mat-form-field>        
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>EOLA Target</mat-label>
            <input *ngIf="!type" [errorStateMatcher]="matcher" [errorStateMatcher]="matcher" formControlName="eola_quality_threshold" pattern="^[0-9]*$" (keypress)="restrictSpecialCharachters($event)" matInput placeholder="Eola Target" required>
            <input *ngIf="type" [errorStateMatcher]="matcher" formControlName="eola_quality_threshold" pattern="^[0-9]*$" (keypress)="restrictSpecialCharachters($event)" matInput placeholder="Eola Target" required>
            <mat-hint style="margin-left: -10px;">EOLA Target supports numeric only</mat-hint>
            <mat-error>
              <span *ngIf="formGroup.get('eola_quality_threshold').hasError('required') && !formGroup.get('eola_quality_threshold').valid">Please Enter EOLA Target.</span>
              <span *ngIf=" !formGroup.get('eola_quality_threshold').hasError('required') && !formGroup.get('eola_quality_threshold').valid">Invalid EOLA Target. Supports numeric only.</span>
            </mat-error>
          </mat-form-field>        
        </td> 
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>EOLA Line</mat-label>
            <input formControlName="eola_line" matInput placeholder="Eola Line">
          </mat-form-field>  
          <mat-error>
            <span *ngIf="!formGroup.get('eola_line').valid && formGroup.get('eola_line').touched">Please enter Test!!!</span>
          </mat-error>      
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Test Target</mat-label>
            <input *ngIf="!type" [errorStateMatcher]="matcher" formControlName="test_yield_threshold" pattern="^[0-9]*$" (keypress)="restrictSpecialCharachters($event)" 
            matInput placeholder="Test Target" required>
            <input *ngIf="type" formControlName="test_yield_threshold" pattern="^[0-9]*$" (keypress)="restrictSpecialCharachters($event)" 
            matInput placeholder="Test Target" required>
            <mat-hint style="margin-left: -10px;">Test Target supports numeric only</mat-hint>
            <mat-error>
              <span *ngIf="formGroup.get('test_yield_threshold').hasError('required') && !formGroup.get('test_yield_threshold').valid">Please Enter Test Target.</span>
              <span *ngIf=" !formGroup.get('test_yield_threshold').hasError('required') && !formGroup.get('test_yield_threshold').valid">Invalid Test Target. Supports numeric only.</span>
            </mat-error>
          </mat-form-field>        
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>eAM Asset Name</mat-label>
            <input formControlName="asset_name" matInput placeholder="eAM Asset Name">
          </mat-form-field>  
          <mat-error>
            <span *ngIf="!formGroup.get('asset_name').valid && formGroup.get('asset_name').touched">Please enter asset name.</span>
          </mat-error>      
        </td>
      </tr>
      <tr>       
        <td>
          <mat-checkbox style="padding-left: 12px;" [checked]="isAggregate" (change)="onAggregateChange($event)" >Agregate</mat-checkbox>
        </td>
      </tr>
      <div style="width:100%;margin: 35px 0;">
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="float:left" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="margin-left:1.5%" *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" routerLink="/lines" type="reset" style="margin-left:2%">Cancel</button>
      </div>

    </table>

  </div>
</form>