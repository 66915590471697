import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SublineService } from './subline.service';
import { Subline } from './subline.model';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'src/app/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlantService } from 'src/app/plant/plant.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LineService } from '../../line.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Globals } from '../../../../app.globals';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import {first } from 'rxjs/operators';

@Component({
  selector: 'app-subline',
  templateUrl: './sublines.component.html',
  styleUrls: ['./sublines.component.css']
})
export class SublinesComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  plantControl: UntypedFormGroup;
  comparePlantFn: ((f1: any, f2: any) => boolean) | null = this.compareByPlantValue;
  compareLineFn: ((f1: any, f2: any) => boolean) | null = this.compareByLineValue;
  sortedSubLineData:any;
  update:any = [];
  sublineSortedData:any ={};
  tempObj:any={};
  statusCode:any;
  adminSub$: Subscription;

  constructor(public sublineService: SublineService,
    public lineService: LineService,
    public plantService: PlantService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    public _global: Globals,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService) { }

  ngOnInit() {
    this.plantControl = this.fb.group({
      plantControl: [null, Validators.required],
      lineControl: [null, Validators.required]
    });
    if (this.plantService.selectedPlant != undefined) {
      this.plantService.aSynchRefreshList().subscribe(data => {
        this.plantControl.get('plantControl').setValue(this.plantService.selectedPlant);
        this.lineService.refreshList(this.plantControl.get('plantControl').value.name);
        if (this.lineService.selectedline != undefined && this.plantService.selectedPlant != undefined) {
          this.lineService.aSynchRefreshList(this.plantService.selectedPlant.id).subscribe(data => {
            this.plantControl.get('lineControl').setValue(this.lineService.selectedline);
            this.sublineService.refreshList(
              this.plantControl.get('plantControl').value.id,
              this.plantControl.get('lineControl').value.id
            );
          });
          //this.sublineService.list=null;
        }
      });

    } else {
      this.plantService.refreshList();

    }
    this.sublineService.list=null;

    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe();
    });
  }

  drop(event: CdkDragDrop<string[]>) {

    moveItemInArray(this.sublineService.list, event.previousIndex, event.currentIndex);

    if(event.previousIndex!=event.currentIndex){
      if(event.currentIndex > event.previousIndex){
        // decrease
        for(let i= event.previousIndex; i<= event.currentIndex-1; i++){
          this.sublineService.list[i].display_order--;
        }
        let current_index_display_order= this.sublineService.list[event.currentIndex-1].display_order;
        this.sublineService.list[event.currentIndex].display_order=current_index_display_order+1;
      }
      else{
        //increment
        if(event.previousIndex>event.currentIndex){
          let nextDisplayOrder=this.sublineService.list[event.currentIndex+1].display_order;
          this.sublineService.list[event.currentIndex].display_order=nextDisplayOrder;
          for(var i=event.currentIndex+1;i<event.previousIndex+1;i++){
              this.sublineService.list[i].display_order++;
            }
         }
      }
      for(var i=0;i<this.sublineService.list.length;i++){
        this.tempObj.id=this.sublineService.list[i].id;
        this.tempObj.display_order=this.sublineService.list[i].display_order;
        this.update.push(this.tempObj);
        this.tempObj={};
      }

      this.sublineSortedData.type="subline";
      this.sublineSortedData.update=this.update;
      // console.log("this.sublineSortedData::",this.sublineSortedData);

      this.sublineService.sortSubLine(this.sublineSortedData).subscribe(res => {

      });
    }

  }

  onPlantSelect() {
    this.plantService.setSelectedPlant(this.plantControl.get('plantControl').value);
    this.lineService.refreshList(this.plantControl.get('plantControl').value.name);
   // this.sublineService.list=null;
    // console.log('selected Plant: ' + this.plantControl.get('plantControl').value.name)
  }

  onLineSelect() {
    this.lineService.setSelectedline(this.plantControl.get('lineControl').value);
    // console.log('selected Line: ' + this.plantControl.get('lineControl').value.name)
    this.sublineService.refreshList(
      this.plantControl.get('plantControl').value.id,
      this.plantControl.get('lineControl').value.id

    );

    // console.log("this.sublineService.list before::",this.sublineService.list);

  }


  onSublineSelect(subline: Subline) {
    this.sublineService.setSelectedSubline(subline);
    if (this._global.admin) {
      this.router.navigate(["/update-subline"]);
    }
    else {
      this.notAuthorizedMessage();
    }
  }

  compareByPlantValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  compareByLineValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  openDialog(subline: Subline) {
    if (this._global.admin) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          this.sublineService.deleteSubline(subline.id).subscribe(res => {
            this._snackBar.open("Record deletion Success", "", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['alert-success'],
            });
            this.sublineService.refreshList(
              this.plantControl.get('plantControl').value.id,
              this.plantControl.get('lineControl').value.id
            );
          });
        }
      });
    }
  }

  createSubline() {
    if (this._global.admin) {
      this.router.navigate(["/create-subline"]);
    }
  }

  notAuthorizedMessage(){
    this._snackBar.open("USER NOT AUTHORISED", "OK", {
      duration: 0,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
