import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigColumn} from './configColumn.model';
import { ConfigValue} from './configValue.model';
@Injectable({
  providedIn: 'root'
})

export class DeltaService {
  readonly rootURL = environment.apiGwUrl+"/Objects";
  readonly displayOrderManager = environment.apiGwUrl + "/displayorderManager";
  readonly valueManagerURL=environment.apiGwUrl+"/subAssemblyValueManager";
  readonly columnManagerURL=environment.apiGwUrl+"/subAssemblyColumnManager";
  readonly updateButtonNameUrl=environment.apiGwUrl+"/subAssemblyButtonManager";
  selectedColumnConfig:ConfigColumn;
  selectedValueConfig:ConfigValue;
  subAssemblyColumnData:any=[];
  lastActivatedTab:number;

  constructor(private http: HttpClient) { }

  getSubAssemblyDelta(plant){
    return this.http.get(this.rootURL + "?type=subAssemblyDeltaList&plantName=" + plant + "&lineId=");
  }

  setSelectedColumnConfig(colcnfg){
    this.selectedColumnConfig=colcnfg;
  }

  setSelectedValueConfig(valcnfg){
    this.selectedValueConfig=valcnfg;
  }

  createUpdateValueConfig(formData: ConfigValue){
    return this.http.post(this.valueManagerURL, formData);
  }


  deleteValueConfig(formData: ConfigValue) {
    return this.http.post(this.valueManagerURL, formData);
  }

  createUpdateColumnConfig(formData: ConfigColumn){
    return this.http.post(this.columnManagerURL , formData);
  }


  deleteColumnConfig(formData: ConfigColumn) {
    return this.http.post(this.columnManagerURL , formData);
  }

  storeSubAssemblyColumnData(arr){
    this.subAssemblyColumnData=arr;
  }

  storeActiveTab(tabIndex){
    this.lastActivatedTab=tabIndex;
  }

  updateDeltaButtonName(data){
    return this.http.post(this.updateButtonNameUrl,data)
  }

  
}
