import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PlantService } from '../../plant.service';
import { EquipmentService } from '../equipment.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equipment-manager',
  templateUrl: './equipment-manager.component.html',
  styleUrls: ['./equipment-manager.component.css']
})
export class EquipmentManagerComponent implements OnInit {


  formGroup: UntypedFormGroup;
  id: number;
  type: boolean = true;
  projectlist: any;
  plantId:any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  equpData: any;
  splant: any =[];
  SelectEqp:any=[{}];
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  compareprojectFn: ((f1: any, f2: any) => boolean) | null = this.compareprojectFnValue;

  constructor(private formBuilder: UntypedFormBuilder,
    public plantService: PlantService,
    private equipmentService: EquipmentService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router:Router) {

    //Initialize form
    this.formGroup = this.formBuilder.group({
      id: [],
      name: [],
      display_name: [],
      plant_id: [],
      project_id:[],
      object: [],
      display_order: [],
      hasUpdate: [],
      asset_name: [],
      plantControl: [],
      projectControl:[],
      projectControlUpdate:[],
      group:[]
    });

  }

  ngOnInit() {
    this.route.data.subscribe(v => {
      if (v.type == 'create') {
        this.type = true;
        this.formGroup.get('plantControl').enable();
      } else {
        this.type = false;
        this.formGroup.get('plantControl').disable();
       // this.formGroup.get('projectControl').setValue(this.plantService.selectedEqup);
      }

    });
    //set selected plant
    if (this.plantService.selectedPlant != undefined) {
      this.plantService.aSynchRefreshList().subscribe(data => {
        this.formGroup.get('plantControl').setValue(this.plantService.selectedPlant);
        this.formGroup.get('plant_id').setValue(this.plantService.selectedPlant.id);
      });
    } else {
      this.plantService.refreshList();
    }
    //set selected line
    if (this.equipmentService.selectedEquipment != undefined) {
      this.formGroup.patchValue(this.equipmentService.selectedEquipment);
      this.equipmentService.selectedEquipment = null;
    }

    if (this.plantService.selectedPlant != undefined) {

    let plantSelcted=this.formGroup.get('plant_id').value;
    this.splant=this.plantService.plantSeclet; 
    this.plantService.refreshProjectList(this.splant).subscribe(data => {
    this.equpData=data;
    console.log("this.equpData::",this.equpData);
    this.SelectEqp= this.equpData.find(e => e.id ==  plantSelcted);
    this.plantId=this.SelectEqp.id;
    console.log("this.SelectEqp::",this.SelectEqp);
    let finaltext=this.SelectEqp.name+"-"+this.SelectEqp.Environment;
    this.formGroup.get('projectControlUpdate').setValue(finaltext);
   
    })
    this.plantService.refreshProjectList(this.splant).subscribe(data => {
      this.projectlist=data;
    });

    }

  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
  compareprojectFnValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
  onPlantSelect() {
    this.formGroup.get('plant_id').setValue(this.formGroup.get('plantControl').value.id);
    let plantSelcted=this.formGroup.get('plantControl').value.name;
   

    this.plantService.refreshProjectList(plantSelcted).subscribe(data => {
      this.projectlist=data;
    });
  }

  onFormSubmit(form: UntypedFormGroup) {
    console.log("form::",form);
     if (form.value.id == null)
       this.insertRecord(form);
    else
       this.updateRecord(form);
  }

  insertRecord(form: UntypedFormGroup) {
   form.value.plant_id=this.formGroup.get('projectControl').value.id;
    this.equipmentService.postEquipment(form.value).subscribe(res => {
      this._snackBar.open("Record creation Success", " ", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
     this.router.navigate(["/equipment"]);
    });
  }

  updateRecord(form: UntypedFormGroup) {
    form.value.plant_id=this.plantId;
    this.equipmentService.putEquipment(form.value).subscribe(res => {
      console.log(res);
      this._snackBar.open("Record Updation Success", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
      this.router.navigate(["/equipment"]);
    });
  }

}
