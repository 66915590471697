import { Injectable} from '@angular/core';


export interface NavItem {

  displayName: string;
  disabled?: boolean;
  show:String;
  iconName: string;
  route?: string;
  children?: NavItem[];
  gea_network:string;
  onClick?(): void;
  href?:string;
}

@Injectable()
export class NavItemService {

navItems: NavItem[] = [

  {
      "displayName": "Logout",
      "disabled": false,
      "show":"mobile",
      "iconName": "",
      "gea_network":"No",
      "children": [
          {
            "displayName": 'Logout',
            "iconName": '',
            "route": 'logout',
            "show":"mobile",
            "gea_network":"No",
          }]

  },

  ];
}


