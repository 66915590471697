import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DeltaService } from '../delta.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PlantService } from '../../plant.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-delta-manager',
  templateUrl: './delta-manager.component.html',
  styleUrls: ['./delta-manager.component.css']
})
export class DeltaManagerComponent implements OnInit {

  valueConfigFormGroup: UntypedFormGroup;
  columnConfigFormGroup: UntypedFormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  formTitle:string;
  type:boolean;
  isColumnConfig:boolean=false;
  isValueConfig:boolean=false;
  isRollup:boolean=false;
  fullColumnData:any=[];
  constructor(
    private formBuilder: UntypedFormBuilder,
    public plantService: PlantService,
    private route: ActivatedRoute,
    private router:Router,
    public deltaservice: DeltaService,
    private _snackBar: MatSnackBar
    
  ) { 

    // Intializing Value Config Form;
    this.valueConfigFormGroup = this.formBuilder.group({
      id:[],
      name:[],
      display_name:[],
      plant_id:[],
      display_order:[],
      C1Value:[],
      C2Value:[],
      C3Value:[],
      C4Value:[],
      C5Value:[],
      hasupdate:[],
      is_active:[],
      Rollup:[],
      plantControl: []
      
    });

    // Intializing Column Config Form;
    this.columnConfigFormGroup = this.formBuilder.group({
      id:[],
      plant_id:[],
      name:[],
      description:[],
      order:[],
      plantControl: [],
    });


  }

  ngOnInit() {

    this.route.data.subscribe(v => {
    this.fullColumnData= this.deltaservice.subAssemblyColumnData
    if (v.type == 'create') {
      this.type = true;
    }else{
      this.type = false;
      this.columnConfigFormGroup.get('plantControl').disable();
    } 
    
    if (v.type == 'create' && v.content=='valueconfig') {
        this.formTitle="Create Value Config";     
        this.isValueConfig=true;
        this.valueConfigFormGroup.get('plantControl').disable();
      

    }else if (v.type == 'create' && v.content=='columnconfig') {
        this.formTitle="Create Column Config";  
        this.isColumnConfig=true;
        this.columnConfigFormGroup.get('plantControl').disable();
    }
    else if (v.type == 'update' && v.content=='valueconfig') {
      this.formTitle="Update Value Config";
      this.isValueConfig=true;
      this.valueConfigFormGroup.patchValue(this.deltaservice.selectedValueConfig);
      if(this.deltaservice.selectedValueConfig.Rollup){
       this.isRollup=true; 
      }
      this.valueConfigFormGroup.get('plantControl').disable();
    }
    else if (v.type == 'update' && v.content=='columnconfig') {
      this.formTitle="Update Column Config";
      this.isColumnConfig=true;
      this.columnConfigFormGroup.patchValue(this.deltaservice.selectedColumnConfig);
      this.columnConfigFormGroup.get('plantControl').disable();
    }
       

    });


  

   if (this.plantService.selectedPlant != undefined) {
    this.plantService.aSynchRefreshList().subscribe(data => {
      this.valueConfigFormGroup.get('plantControl').setValue(this.plantService.selectedPlant);
      this.columnConfigFormGroup.get('plantControl').setValue(this.plantService.selectedPlant);
      //this.formGroup.get('plant_id').setValue(this.plantService.selectedPlant.id);
    });
  } else {
    this.plantService.refreshList();
  }
  
  }

  

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  columnConfigSubmit(formData){
    var finalData=formData.value;
    if(this.type){
      finalData.type="create";
      finalData.plant_id=this.plantService.selectedPlant.name;
    }else{
      finalData.type="update";
    } 
      this.deltaservice.createUpdateColumnConfig(finalData).subscribe(resp => {
        if(resp['Body']['RecordCount']>0){
          this.showSuccessMessage();
          this.router.navigate(["/subassemblydelta"]);
        }else{
          this.showFailureMessage();
        }
      });
  
  }

  valueConfigSubmit(formData){
    var finalData=formData.value;
    if(this.type){
      finalData.plant_id=this.plantService.selectedPlant.name;
      finalData.type="create"
    }else{
      finalData.type="update";
    }
    
    if(this.isRollup){
      finalData.Rollup="1"
    }else{
      finalData.Rollup="0"
    }
    
      finalData=this.checkForNullValues(finalData);
      this.deltaservice.createUpdateValueConfig(finalData).subscribe(resp => {
        if(resp['Body']['RecordCount']>0){
          this.showSuccessMessage();
          this.router.navigate(["/subassemblydelta"]);
        }else{
          this.showFailureMessage();
        }
      });
  
  }

  onPlantSelect(src){
    
    if(src=='valueconfig'){
      let selectedPlant=this.valueConfigFormGroup.get('plantControl').value;
      this.plantService.setSelectedPlant(selectedPlant);
    }else if(src=='columnconfig'){
      let selectedPlant=this.columnConfigFormGroup.get('plantControl').value;
      this.plantService.setSelectedPlant(selectedPlant);
    }

  }

  showSuccessMessage(){
    if(this.type){
    this._snackBar.open("Record Creation Success", " ", {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['alert-success'],
    });
   }else{
    this._snackBar.open("Record Updation Success", " ", {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['alert-success'],
    });
   }
  }

  showFailureMessage(){
    if(this.type){
      this._snackBar.open("Record Creation", "Failure", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
     }else{
      this._snackBar.open("Record Updation", "Failure", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
     }
  }

  onRollupChange(event){
    if(event.checked){
      this.isRollup=true;
    }else{
      this.isRollup=false;
    }
  }

   checkForNullValues(object){
    if(object.C1Value==null){
      object.C1Value="";
    }
    if(object.C2Value==null){
      object.C2Value="";
    }
    if(object.C3Value==null){
      object.C3Value="";
    }
    if(object.C4Value==null){
      object.C4Value="";
    }
    if(object.C5Value==null){
      object.C5Value="";
    }
    return object;
   }


}
