import { Component, OnInit } from '@angular/core';
import { PlantService } from '../plant.service';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EquipmentService } from './equipment.service';
import { Equipment } from './equipment.model';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'src/app/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Globals } from '../../app.globals';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import {first } from 'rxjs/operators';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {


  plantControl: UntypedFormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  sortedEquipmentData: any;
  update: any = [];
  equipmentSortedData: any = {};
  tempObj: any = {};
  statusCode: any;
  adminSub$: Subscription;

  constructor(
    public plantService: PlantService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public equipmentService: EquipmentService,
    private fb: UntypedFormBuilder,
    public _global: Globals,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService) { }

  ngOnInit() {
    this.plantControl = this.fb.group({
      plantControl: [null, Validators.required]
    });
    if (this.plantService.selectedPlant != undefined) {
      this.plantService.aSynchRefreshList().subscribe(data => {
        this.plantControl.get('plantControl').setValue(this.plantService.selectedPlant);
        this.equipmentService.refreshList(this.plantControl.get('plantControl').value.name);
        //this.equipmentService.refreshList(this.plantControl.get('plantControl').value.id);
      });
    } else {
      this.plantService.refreshList();
    }

    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe();
    });

  }

  drop(event: CdkDragDrop<string[]>) {
    this.update = [];
    moveItemInArray(this.equipmentService.list, event.previousIndex, event.currentIndex);

    if (event.previousIndex != event.currentIndex) {
      if (event.currentIndex > event.previousIndex) {
        // decrease
        for (let i = event.previousIndex; i <= event.currentIndex - 1; i++) {
          this.equipmentService.list[i].display_order--;
        }
        let current_index_display_order = this.equipmentService.list[event.currentIndex - 1].display_order;
        this.equipmentService.list[event.currentIndex].display_order = current_index_display_order + 1;
      }
      else {
        //increment
        if (event.previousIndex > event.currentIndex) {
          let nextDisplayOrder = this.equipmentService.list[event.currentIndex + 1].display_order;
          this.equipmentService.list[event.currentIndex].display_order = nextDisplayOrder;
          for (var i = event.currentIndex + 1; i < event.previousIndex + 1; i++) {
            this.equipmentService.list[i].display_order++;
          }
        }
      }
      for (var i = 0; i < this.equipmentService.list.length; i++) {
        this.tempObj.id = this.equipmentService.list[i].id;
        this.tempObj.display_order = this.equipmentService.list[i].display_order;
        this.update.push(this.tempObj);
        this.tempObj = {};
      }

      this.equipmentSortedData.type = "equipment";
      this.equipmentSortedData.update = this.update;
      // console.log("this.equipmentSortedData::", this.equipmentSortedData);

      this.equipmentService.sortEquipment(this.equipmentSortedData).subscribe(res => {

      });
    }
  }

  onPlantSelect() {
    this.plantService.setSelectedPlant(this.plantControl.get('plantControl').value);
    this.equipmentService.refreshList(this.plantControl.get('plantControl').value.name);
  }

  onEquipmentSelect(equipment: Equipment) {
    this.equipmentService.setSelectedEquipment(equipment);
    if (this._global.admin) {
      this.router.navigate(["/update-equipment"]);
    }
    else {
      this.notAuthorizedMessage();
    }
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  openDialog(equipment: Equipment) {
    if (this._global.admin) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          this.equipmentService.deleteEquipment(equipment.id).subscribe(res => {
            this._snackBar.open("Record deletion Success", " ", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['alert-success'],
            });
            this.equipmentService.refreshList(this.plantControl.get('plantControl').value.name);
          });
        }
      });
    }
  }

  createEquipment() {
    if (this._global.admin) {
      this.router.navigate(["/create-equipment"]);
    }
  }

  notAuthorizedMessage() {
    this._snackBar.open("USER NOT AUTHORISED", "OK", {
      duration: 0,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
