<h1 style="text-align:center;color:#3f51b5" *ngIf="type">Create Equipment</h1>
<h1 style="text-align:center;color:#3f51b5" *ngIf="!type">Update Equipment</h1>



<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit(formGroup)">
  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" required [compareWith]="compareFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{formGroup.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>
 
  <ng-container *ngIf="type">
  <mat-form-field class="slicer">
    <mat-label>Project</mat-label>
    <mat-select formControlName="projectControl" required >
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let project of projectlist" [value]="project">
        {{project.name}} - {{project.project_sc}}
      </mat-option>
    </mat-select>
    <mat-hint>{{formGroup.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>
</ng-container>

  <ng-container *ngIf="!type">
   <mat-form-field class="slicer">
      <mat-label>Project</mat-label>
      <mat-select formControlName="projectControlUpdate" disabled required [compareWith]="compareprojectFn">
        <mat-option  [value]="SelectEqp">
          {{SelectEqp.name}} - {{SelectEqp.project_sc}}
        </mat-option>
      </mat-select>
  
      <mat-hint>{{formGroup.get('plantControl').value?.display_name}}</mat-hint>
    </mat-form-field>  
  </ng-container> 

 

  <div class="parent">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="plant_id">
    <input type="hidden" formControlName="project_id">
    <table class="lineTable">
      <!-- <tr>
        <td colspan="2"><span class="note--label" style="font-size: 14px;">* Equipment Name supports only alpha numeric charachters e.g. A-Z,a-z,0-9</span></td>
      </tr> -->
      <tr>
        <td colspan="2">
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Equipment Name</mat-label>
            <input formControlName="name" class="eqpName" matInput placeholder="Equipment Name" required pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)">
            <mat-hint style="margin-left: -10px;">Equipment Name supports A-Z,a-z,0-9</mat-hint>
          </mat-form-field>
          <mat-error>
            <!-- <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">Please enter Equipment Name !!!</span> -->
            <span *ngIf="formGroup.get('name').touched && !formGroup.get('name').dirty && !formGroup.get('name').valid">Please enter equipment name !!!</span> 
            <span *ngIf="formGroup.get('name').dirty && !formGroup.get('name').valid">Please enter valid equipment name !!!</span>
          
          </mat-error>
        </td>

      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Object</mat-label>
            <input formControlName="object" matInput placeholder="Object" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('object').valid && formGroup.get('object').touched">Please enter Object !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Order</mat-label>
            <input formControlName="display_order" matInput placeholder="Display Order" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('display_order').valid && formGroup.get('display_order').touched">Please enter Display Order !!!</span>
          </mat-error>
        </td>


      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>eAM Asset Name</mat-label>
            <input formControlName="asset_name" matInput placeholder="eAM Asset Name">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('asset_name').valid && formGroup.get('asset_name').touched">Please enter eAM Asset Name !!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Group</mat-label>
            <input formControlName="group" matInput placeholder="Group" autocomplete="off" pattern="^[a-zA-Z_0-9]*$" >
          </mat-form-field>
        </td>
      </tr>

      <div style="width:100%">
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="float:left" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="margin-left:1.5%" *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" routerLink="/equipment" type="reset" style="margin-left:2%">Cancel</button>

      </div>



    </table>

  </div>
</form>