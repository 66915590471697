import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PlantService } from '../plant.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-plant-manager',
  templateUrl: './plant-manager.component.html',
  styleUrls: ['./plant-manager.component.css']
})
export class PlantManagerComponent implements OnInit {
  formGroup: UntypedFormGroup;
  id: number;
  type: boolean = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private formBuilder: UntypedFormBuilder,
    public plantService: PlantService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute) {

    //Initialize form
    this.formGroup = this.formBuilder.group({
      id: [],
      name: [],
      display_name: [],
      project_sc: [],
      project_tr: [],
      display_order: [],
      Environment: [],
      time_zone: [],
      MSSQLConnection: [],
      OPCConnection: [],
      tagProvider: [],
      Site: [],
      auto_create: [],
      Type: [],
    });

  }

  ngOnInit() {
    this.route.data.subscribe(v => {
      if (v.type == 'create') {
        this.type = true;
        this.plantService.selectedPlant = null;
      } else {
        this.type = false;
      }
    });
    //set selected line
    if (this.plantService.selectedPlant != undefined) {
      this.formGroup.patchValue(this.plantService.selectedPlant);
      this.plantService.selectedPlant = null;
    }
  }

  onFormSubmit(form: UntypedFormGroup) {
    /*if (form.value.id == null)
      this.insertRecord(form);
    else
      this.updateRecord(form);*/
  }

  insertRecord(form: UntypedFormGroup) {
    this.plantService.postPlant(form.value).subscribe(res => {
      this._snackBar.open("Record creation Success", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
      this.type = false;
    });
  }

  updateRecord(form: UntypedFormGroup) {
    this.plantService.putPlant(form.value).subscribe(res => {
      console.log(res);
      this._snackBar.open("Record Updation Success", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
      this.plantService.refreshList();
    });
  }



}
