import { Component, OnInit } from '@angular/core';
import { LineService } from './line.service';
import { PlantService } from '../plant.service';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Line } from './line.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmationDialog } from '../../confirmation-dialog.component';
import { MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { Globals } from '../../app.globals';
import {GeaMesCognitoAuthService } from '@gea-mes/cognito';
import {Subscription } from 'rxjs';
import {first } from 'rxjs/operators';

@Component({
  selector: 'app-lines',
  templateUrl: './lines.component.html',
  styleUrls: ['./lines.component.css']
})
export class LinesComponent implements OnInit {

  plantControl: UntypedFormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  statusCode: any;
  adminSub$: Subscription;

  constructor(public lineService: LineService,
    public plantService: PlantService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    public _global: Globals,
    public geaMesCognitoAuthService: GeaMesCognitoAuthService) { }

  ngOnInit() {
    this.plantControl = this.fb.group({
      plantControl: [null, Validators.required]
    });
    if (this.plantService.selectedPlant != undefined) {
      this.plantService.aSynchRefreshList().subscribe(data => {
        this.plantControl.get('plantControl').setValue(this.plantService.selectedPlant);
        this.lineService.refreshList(this.plantControl.get('plantControl').value.name);
      });
    } else {
      this.plantService.refreshList();
    }

    this.adminSub$ = this.geaMesCognitoAuthService.isUserInRole("Admin").pipe(first()).subscribe( out => {
      this._global.admin = out;
      if (this.adminSub$) this.adminSub$.unsubscribe();
    });
  }

  onPlantSelect() {
    this.plantService.setSelectedPlant(this.plantControl.get('plantControl').value);
    this.lineService.refreshList(this.plantControl.get('plantControl').value.name);
  }

  onLineSelect(line: Line) {
    this.lineService.setSelectedline(line);
    if (this._global.admin) {
      this.router.navigate(["/update-line"]);
    }
    else {
      this.notAuthorizedMessage();
    }
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }

  openDialog(line: Line) {
    if (this._global.admin) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: 'Are you sure want to delete?',
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          this.lineService.deleteLine(line.id).subscribe(res => {
            this._snackBar.open("Record deletion Success", " ", {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['alert-success'],
            });
            this.lineService.refreshList(this.plantService.plantSeclet);
          });
        }
      });
    }

  }

  createLine() {
    if (this._global.admin) {
      this.router.navigate(["/create-line"]);
    }
  }

  notAuthorizedMessage() {
    this._snackBar.open("USER NOT AUTHORISED", "OK", {
      duration: 0,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
