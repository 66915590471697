<h1 style="text-align:center;color:#3f51b5" *ngIf="type">Create Subline</h1>
<h1 style="text-align:center;color:#3f51b5" *ngIf="!type">Update Subline</h1>


<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()">
  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="comparePlantFn" required>
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{formGroup.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>

<mat-form-field class="slicer">
  <mat-label>Line</mat-label>
  <mat-select (selectionChange)="onLineSelect()" formControlName="lineControl" [compareWith]="compareLineFn" required>
    <mat-option value="null" disabled selected>Select...</mat-option>
    <mat-option *ngFor="let line of lineService.list" [value]="line">
      {{line.name}}
    </mat-option>
  </mat-select>
  <mat-hint>{{formGroup.get('lineControl').value?.display_name}}</mat-hint>
</mat-form-field>
  
  <div class="parent">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="plant_id">
    <input type="hidden" formControlName="line_id">
    <table class="lineTable">

      <tr>
        <td colspan="2">
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Subline Name</mat-label>
            <input formControlName="name" matInput placeholder="Subline Name" required pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)">
            <mat-hint style="margin-left: -10px;">Subline Name supports A-Z,a-z,0-9</mat-hint>
          </mat-form-field>
          <mat-error>
            <!-- <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">Please enter Subline Name !!!</span> -->
            <span *ngIf="formGroup.get('name').touched && !formGroup.get('name').dirty && !formGroup.get('name').valid">Please enter subline name !!!</span> 
            <span *ngIf="formGroup.get('name').dirty && !formGroup.get('name').valid">Please enter valid subline name !!!</span>
          
          </mat-error>
        </td>

      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Status</mat-label>
            <input formControlName="status" matInput placeholder="Status" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('status').valid && formGroup.get('status').touched">Please enter Status !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Order</mat-label>
            <input formControlName="display_order" matInput placeholder="Display Order" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('display_order').valid && formGroup.get('display_order').touched">Please enter Display Order !!!</span>
          </mat-error>
        </td>


      </tr>


      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Target</mat-label>
            <input formControlName="target" matInput placeholder="Target" >
           <!--  <mat-hint *ngIf="!type">Object comes from Proficy can't be updated</mat-hint> -->
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('target').valid && formGroup.get('target').touched">Please enter Target !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Starts</mat-label>
            <input formControlName="starts" matInput placeholder="Starts" >
           <!--  <mat-hint *ngIf="!type">Object comes from Proficy can't be updated</mat-hint> -->
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('starts').valid && formGroup.get('starts').touched">Please enter Starts !!!</span>
          </mat-error>
        </td>

      </tr>


      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Packed</mat-label>
            <input formControlName="packed" matInput placeholder="Packed" >
            <!-- <mat-hint *ngIf="!type">Object comes from Proficy can't be updated</mat-hint> -->
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('packed').valid && formGroup.get('packed').touched">Please enter Packed !!!</span>
          </mat-error>
        </td>
        <td>
           <mat-form-field class="demo-full-width" appearance="outline">
          <mat-label>eAm Asset Name</mat-label>
          <input formControlName="asset_name" matInput placeholder="asset_name" >
        </mat-form-field>
        <mat-error>
          <span *ngIf="!formGroup.get('asset_name').valid && formGroup.get('asset_name').touched">Please enter eAm Asset Name !!!</span>
        </mat-error>
        </td>
      </tr>

      <div style="width:100%">
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="float:left" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="margin-left:1.5%" *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" routerLink="/sublines" type="reset" style="margin-left:2%">Cancel</button>

      </div>



    </table>

  </div>
</form>