<!-- <a mat-raised-button color="primary" class="newButton" routerLink="/create-plant" disabled="disabled">Add New Plant</a> -->
<!-- Click Event disabled for Add New Plant -->
<a mat-raised-button color="primary" class="newButton" disabled="true" > Add New Plant</a>
<div class="table-padding">
  <table class="lineTable">
    <tr>
      <th width="16%">Name</th>
      <th width="16%">Display Name</th>
      <th width="16%">Project</th>
      <th width="16%">OPC Connection</th>
      <th width="16%">Tag Provider</th>
      <th width="16%">Display Order</th>
    </tr>
    <tr *ngFor="let plant of plantService.listData" >
      <td width="16%">{{plant.name}}</td>
      <td width="16%">{{plant.display_name}}</td>
      <td width="16%">{{plant.project_sc}}</td>
      <td width="16%">{{plant.OPCConnection}}</td>
      <td width="16%">{{plant.tagProvider}}</td>
      <td width="16%">{{plant.display_order}}</td>
    </tr>
  </table>
</div>
