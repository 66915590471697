import { Component, OnInit } from "@angular/core";
import { LogOutService } from "../services/log-out.service";

@Component({
  selector: "app-main-content",
  templateUrl: "./main-content.component.html",
  styleUrls: ["./main-content.component.scss"],
})
export class MainContentComponent implements OnInit {
  checkLoginStatus: boolean;

  constructor(public logOutService: LogOutService) {}

  ngOnInit(): void {}

  ngDoCheck() {
    this.checkLoginStatus = this.logOutService.checkLoginUserStatus;
  }
}
