import { Injectable } from '@angular/core';
import { Line } from './line.model';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LineService {
  list: Line[];
  selectedline: Line;
  readonly rootURL = environment.apiGwUrl+"/Objects"
  readonly lineManagerURL = environment.apiGwUrl+"/lineManager"

  constructor(private http: HttpClient) { }

  refreshList(id: number) {
    this.http.get(this.rootURL + "?type=line&plantName=" + id + "&lineId=")
      .toPromise().then(res => this.list = res as Line[]);
  }

  aSynchRefreshList(id: number) {
    return this.http.get(this.rootURL + "?type=line&plantName=" + id + "&lineId=");
  }

  setSelectedline(line: Line) {
    this.selectedline = line;
  }

  postLine(formData: Line) {
    return this.http.post(this.lineManagerURL + '?type=create', formData);
  }

  putLine(formData: Line) {
    return this.http.post(this.lineManagerURL + '?type=update', formData);

  }

  deleteLine(id: number) {
    return this.http.post(this.lineManagerURL + '?type=delete&id=', { id: id });
  }

}
