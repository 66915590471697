import { Injectable } from '@angular/core';
import { Plant } from './plant.model';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PlantService {

  list: Plant[];
  projectlist: Plant[];
  listData: Plant[];
  selectedPlant: Plant;
  plantSeclet: any;
  selectedEqup:Plant;
  regEx:any=/^[A-Za-z0-9 ]*$/;
  readonly rootURL = environment.apiGwUrl + "/Objects"
  readonly plantManagerURL = environment.apiGwUrl + "/plantManager"

  constructor(private http: HttpClient) { }

  refreshList() {
    this.http.get(this.rootURL + "?type=plantList&plantName=&lineId=")
      .toPromise().then(res => this.list = res as Plant[]);
  }

  refreshProjectList(plant) {
    return this.http.get(this.rootURL + "?type=projectList&plantName="+plant+"&lineId=");
     // .toPromise().then(res => this.projectlist = res as Plant[]);
  }

  refreshListData() {
    this.http.get(this.rootURL + "?type=plant&plantName=&lineId=")
      .toPromise().then(res => this.listData = res as Plant[]);
  }


  aSynchRefreshList() {
    return this.http.get(this.rootURL + "?type=plant&plantName=&lineId=");

  }

  setSelectedPlant(plant: Plant) {
    this.selectedPlant = plant;
    this.plantSeclet=plant.name;
  }

  setSelectedEqup(plant: Plant) {
    this.selectedPlant = plant;
  
  }
  postPlant(formData: Plant) {
    return this.http.post(this.plantManagerURL + '?type=create', formData);
  }

  putPlant(formData: Plant) {
    return this.http.post(this.plantManagerURL + '?type=update', formData);
  }

  restrictSpecialCharachters(evt: any): boolean{
   // let regEx=/^[A-Za-z0-9]*$/
    if (this.regEx.test(evt.key)) {
     return true;
    } else {
     console.log("Fail REGEX");
     return false;
    
    }
  }
  
}
