import { Injectable } from '@angular/core';
import { Subline } from './subline.model';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SublineService {

  list: Subline[];
  selectedSubline: Subline;
  readonly rootURL = environment.apiGwUrl + "/Objects"
  readonly sublineManagerURL = environment.apiGwUrl + "/sublineManager"
  readonly displayOrderManager = environment.apiGwUrl + "/displayorderManager"
  sublineSelect: any;

  constructor(private http: HttpClient) { }

  refreshList(plantId: number, lineId: number) {
    this.http.get(this.rootURL + "?type=subline&plantName=" + plantId + "&lineId=" + lineId)
      .toPromise().then(res => this.list = res as Subline[]);  
  }

  aSynchRefreshList() {
    return this.http.get(this.rootURL + "?type=plant&plantId=&lineId=");

  }

  setSelectedSubline(subline: Subline) {
    this.selectedSubline = subline;
    this.sublineSelect=subline.name;
  }
  postSubline(formData: Subline) {
    return this.http.post(this.sublineManagerURL + '?type=create', formData);
  }

  putSubline(formData: Subline) {
    return this.http.post(this.sublineManagerURL + '?type=update', formData);

  }

  deleteSubline(id: number) {
    return this.http.post(this.sublineManagerURL + '?type=delete&id=', { id: id });
  }

  sortSubLine(obj: any){
    console.log("data obj",obj);
    return this.http.post(this.displayOrderManager, obj);
  }
}
