import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SideNavComponent } from './side-nav/side-nav.component';
import { LinesComponent } from './plant/lines/lines.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LineService } from './plant/lines/line.service';
import { PlantService } from './plant/plant.service';
import { PlantComponent } from './plant/plant.component';
import { LineMangerComponent } from './plant/lines/line-manger/line-manger.component';
import { ConfirmationDialog } from './confirmation-dialog.component';
import { PlantManagerComponent } from './plant/plant-manager/plant-manager.component';
import { EquipmentComponent } from './plant/equipment/equipment.component';
import { EquipmentService } from './plant/equipment/equipment.service';
import { EquipmentManagerComponent } from './plant/equipment/equipment-manager/equipment-manager.component';
import { SublineManagerComponent } from './plant/lines/line-manger/sublines/subline-manager/subline-manager.component';
import { SublinesComponent } from './plant/lines/line-manger/sublines/sublines.component';
import { SublineService } from './plant/lines/line-manger/sublines/subline.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {A11yModule} from '@angular/cdk/a11y';
import { DeltaComponent } from './plant/subAssemblyDelta/delta.component';
import { DeltaManagerComponent } from './plant/subAssemblyDelta/delta-manager/delta-manager.component';
import {DeltaService}  from './plant/subAssemblyDelta/delta.service';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { Globals } from './app.globals';
import {GeaMesNavbarModule}from '@gea-mes/navbar';
import { LogOutPageComponent } from './log-out-page/log-out-page.component';
import {NavItem,NavItemService} from './nav-item.service';
import { MainContentComponent } from './main-content/main-content.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SideNavComponent,
        LinesComponent,
        PlantComponent,
        LineMangerComponent,
        ConfirmationDialog,
        PlantManagerComponent,
        EquipmentComponent,
        EquipmentManagerComponent,
        SublineManagerComponent,
        SublinesComponent,
        DeltaComponent,
        DeltaManagerComponent,
        LogOutPageComponent,
        MainContentComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserModule,
        MatInputModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        BrowserAnimationsModule,
        MatRippleModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatCardModule,
        MatListModule,
        MatSnackBarModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatCheckboxModule,
        AppRoutingModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        PortalModule,
        ScrollingModule,
        MatTabsModule,
        GeaMesNavbarModule
    ],
    providers: [EquipmentService, LineService, PlantService, SublineService, DeltaService, Globals, NavItemService],
    bootstrap: [AppComponent]
})
export class AppModule { }
