import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlantComponent } from './plant/plant.component';
import { LineMangerComponent } from './plant/lines/line-manger/line-manger.component';
import { PlantManagerComponent } from './plant/plant-manager/plant-manager.component';
import { EquipmentComponent } from './plant/equipment/equipment.component';
import { EquipmentManagerComponent } from './plant/equipment/equipment-manager/equipment-manager.component';
import { LinesComponent } from './plant/lines/lines.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, COGNITO_CONFIG, GeaMesAuthInterceptor, APPLICATION_NAME, ALLOW_SCREENAUTH, APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { SublinesComponent } from './plant/lines/line-manger/sublines/sublines.component';
import { SublineManagerComponent } from './plant/lines/line-manger/sublines/subline-manager/subline-manager.component';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeltaComponent } from './plant/subAssemblyDelta/delta.component';
import { DeltaManagerComponent } from './plant/subAssemblyDelta/delta-manager/delta-manager.component';
import { appinfo } from './appinfo';
import { GEA_MES_NAVBAR_COGNITO_CONFIG,GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS,GEA_ENVIROMENT,GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT} from '@gea-mes/navbar';
import { GeaMesCognitoAuthService} from '@gea-mes/cognito';
import { BehaviorSubject} from 'rxjs';
import { NavItem } from './nav-item.service';
import { LogOutPageComponent} from './log-out-page/log-out-page.component';

const appRoutes: Routes = [
  //{ path: '', component: GeaMesLoginComponent },
  { path: 'login', component: GeaMesLoginComponent },
  { path: 'logout', component: LogOutPageComponent },
  {
    path: '', canActivate: [GeaMesCognitoAuthGuard],
    children: [
      { path: 'plants', component: PlantComponent },
      { path: 'create-plant', component: PlantManagerComponent, data: { type: 'create' } },
      { path: 'update-plant', component: PlantManagerComponent, data: { type: 'update' } },
      { path: 'lines', component: LinesComponent },
      { path: 'create-line', component: LineMangerComponent, data: { type: 'create' } },
      { path: 'update-line', component: LineMangerComponent, data: { type: 'update' } },
      { path: 'equipment', component: EquipmentComponent },
      { path: 'create-equipment', component: EquipmentManagerComponent, data: { type: 'create' } },
      { path: 'update-equipment', component: EquipmentManagerComponent, data: { type: 'update' } },
      { path: 'sublines', component: SublinesComponent },
      { path: 'create-subline', component: SublineManagerComponent, data: { type: 'create' } },
      { path: 'update-subline', component: SublineManagerComponent, data: { type: 'update' } },
      { path: 'subassemblydelta', component: DeltaComponent },
      { path: 'create-valueConfig', component: DeltaManagerComponent, data: { type: 'create', content: 'valueconfig' } },
      { path: 'update-valueConfig', component: DeltaManagerComponent, data: { type: 'update', content: 'valueconfig' } },
      { path: 'create-columnConfig', component: DeltaManagerComponent, data: { type: 'create', content: 'columnconfig' } },
      { path: 'update-columnConfig', component: DeltaManagerComponent, data: { type: 'update', content: 'columnconfig' } }
    ]
  },
  { path: '**', redirectTo: '/plants' }
  //{ path: '**', redirectTo: '/subassemblydelta'}
];


@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(appRoutes, {})],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth },
    { provide: GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE, useClass: GeaMesCognitoAuthService },
    { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue:"Brilliant Config" },
    { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
    { provide: GEA_MES_NAVBAR_COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue:new BehaviorSubject<NavItem[]>([])  },
    { provide: GEA_ENVIROMENT, useValue: environment },
    { provide: GEA_MES_NAVBAR_APP_ID_FOR_WHATSNEW_TEXT,useValue:"BrilliantConfig"}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
