<form [formGroup]="plantControl">
  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="comparePlantFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{plantControl.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>

  <mat-form-field class="slicer">
    <mat-label>Line</mat-label>
    <mat-select (selectionChange)="onLineSelect()" formControlName="lineControl" [compareWith]="compareLineFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let line of lineService.list" [value]="line">
        {{line.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{plantControl.get('lineControl').value?.name}}</mat-hint>
  </mat-form-field>

  <a mat-raised-button color="primary" class="newButton" (click)= "createSubline()" [disabled]=!this._global.admin >Add Subline</a>
</form>

<div class="table-padding">
  <table class="lineTable example-list" id="table" cdkDropList (cdkDropListDropped)="drop($event)">
    <tr>
      <th width="16%">Name</th>
      <th width="16%">packed</th>
      <th width="16%">Status</th>
      <th width="16%">Target</th>
      <th width="16%">Starts</th>
      <th width="16%">Display Order</th>
    </tr>
    <tr *ngFor="let subline of sublineService.list" style="cursor:pointer" class="example-box" cdkDrag>
      <td width="16%" (click)="onSublineSelect(subline)">{{subline.display_name}}</td>
      <td width="16%" (click)="onSublineSelect(subline)"> {{subline.packed}}</td>
      <td width="16%" (click)="onSublineSelect(subline)">{{subline.status}}</td>
      <td width="16%" (click)="onSublineSelect(subline)">{{subline.target}}</td>
      <td width="16%" (click)="onSublineSelect(subline)">{{subline.starts}}</td>
       <td width="16%" (click)="onSublineSelect(subline)">{{subline.display_order}}</td>
      <td width="5%"><button mat-raised-button (click)="openDialog(subline)" color="warn" [disabled]=!this._global.admin >X</button></td>
    </tr>
  </table>
</div>
