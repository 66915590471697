
<!-- Column Config Tab & Data -->
<mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" [selectedIndex]="activeTabIndex" animationDuration="0ms">

  <mat-tab label="Column Config" >
    <p></p>

    <form [formGroup]="plantControl">
      <mat-form-field class="slicer">
        <mat-label>Plant</mat-label>
        <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="compareFn">
          <mat-option value="null" disabled selected>Select...</mat-option>
          <mat-option *ngFor="let plant of plantService.list" [value]="plant">
            {{plant.name}}
          </mat-option>
        </mat-select>
        <mat-hint>{{plantControl.get('plantControl').value?.display_name}}</mat-hint>
      </mat-form-field>
      <a mat-raised-button color="primary" class="newButton" (click) = "createColumnConfig()" [ngStyle]="{'pointer-events': columnConfigDisbale ? 'none' : null}" [disabled]=!this._global.admin >Add New Column Config</a>
    </form>


    <div class="table-padding">
      <table class="lineTable example-list" id="table" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="showTable">
          <tr>
            <th width="30%">Name</th>
            <th width="35%">Description</th>
            <th width="30%">Display Order</th>
          </tr>

           <tr *ngFor="let data of subAsssemblyDeltaData[0].SubFreeFormValue; index as i" style="cursor:pointer" class="example-box" >
            <td width="30%" (click)="onColumnConfigsSelect(data)">{{data.name}}</td>
            <td width="35%" (click)="onColumnConfigsSelect(data)">{{data.description}} </td>
            <td width="30%" (click)="onColumnConfigsSelect(data)" >{{data.order}}</td>
            <!-- <td width="10%"><button mat-raised-button (click)="deleteColumnConfig(data,i)" color="warn" >X</button></td> -->
          </tr>

        </table>
  </div>
  </mat-tab>

  <!-- Value Config Tab & Data  -->
  <mat-tab label="Value Config"  >
    <p></p>

    <form [formGroup]="plantControl">
      <mat-form-field class="slicer">
        <mat-label>Plant</mat-label>
        <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="compareFn">
          <mat-option value="null" disabled selected>Select...</mat-option>
          <mat-option *ngFor="let plant of plantService.list" [value]="plant">
            {{plant.name}}
          </mat-option>
        </mat-select>
        <mat-hint>{{plantControl.get('plantControl').value?.display_name}}</mat-hint>
      </mat-form-field>
      <a mat-raised-button color="primary" class="newButton" (click)="addNewValueConfig()" [ngStyle]="{'pointer-events': valueConfigDisbale ? 'none' : null}" [disabled]=!this._global.admin >Add New Value Config</a>

    </form>


    <div class="table-padding">
      <table class="lineTable example-list" id="table" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="showTable">
          <tr >
            <th *ngFor="let header of valueTableHeader" >{{header}}</th>
          </tr>

           <tr *ngFor="let valueData of subAsssemblyDeltaData[0].SubFreeFormConfig" style="cursor:pointer" class="example-box" >
            <td (click)="onValueConfigsSelect(valueData)">{{valueData.name}}</td>
            <td (click)="onValueConfigsSelect(valueData)">{{valueData.display_name}} </td>
            <td style="text-align: center;" (click)="onValueConfigsSelect(valueData)">{{valueData.display_order}} </td>
            <td (click)="onValueConfigsSelect(valueData)" *ngIf='subAsssemblyDeltaData[0].SubFreeFormValue[0] && valueData.C1Value!=" "'>{{valueData.C1Value}}</td>
            <td (click)="onValueConfigsSelect(valueData)" *ngIf='subAsssemblyDeltaData[0].SubFreeFormValue[1] && valueData.C2Value!=" "'>{{valueData.C2Value}}</td>
            <td  (click)="onValueConfigsSelect(valueData)" *ngIf='subAsssemblyDeltaData[0].SubFreeFormValue[2] && valueData.C3Value!=" "'>{{valueData.C3Value}}</td>
            <td  (click)="onValueConfigsSelect(valueData)" *ngIf= 'subAsssemblyDeltaData[0].SubFreeFormValue[3] && valueData.C4Value!=" "'>{{valueData.C4Value}} </td>
            <td  (click)="onValueConfigsSelect(valueData)" *ngIf='subAsssemblyDeltaData[0].SubFreeFormValue[4] && valueData.C5Value!=" "'>{{valueData.C5Value}}</td>
            <td  style="text-align: center;" (click)="onValueConfigsSelect(valueData)" >{{valueData.Rollup}}</td>
            <td width="5%"><button mat-raised-button (click)="deleteValueConfig(valueData)" color="warn" [disabled]=!this._global.admin >X</button></td>
          </tr>

        </table>

   </div>
  </mat-tab>

  <mat-tab label="Name Config"  >
    <p></p>

    <form [formGroup]="plantControl">
      <mat-form-field class="slicer">
        <mat-label>Plant</mat-label>
        <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="compareFn">
          <mat-option value="null" disabled selected>Select...</mat-option>
          <mat-option *ngFor="let plant of plantService.list" [value]="plant">
            {{plant.name}}
          </mat-option>
        </mat-select>
        <mat-hint>{{plantControl.get('plantControl').value?.display_name}}</mat-hint>
      </mat-form-field>
   </form>
   <p></p>

   <form [formGroup]="deltaNameContrl" (ngSubmit)="nameConfigSubmit()"  >
    <table style="width: 30%;background-color: #fafafa" *ngIf="dynamicButtonTableShow">
      <tr>
        <td width="90%" style="background-color: #fafafa;">
          <mat-form-field class="demo-full-width" appearance="outline"  style="width: 90% !important;margin-left: 15px">
            <mat-label>Sub-Assembly Delta's Name</mat-label>
            <input formControlName="deltaName" matInput placeholder="Name" >
          </mat-form-field>
          <!-- <mat-error>
             <span *ngIf="!deltaNameContrl.get('deltaName').valid && deltaNameContrl.get('deltaName').touched">Please enter delta name !!!</span>
          </mat-error> -->
        </td>
        <td style="background-color: #fafafa;">
          <button mat-raised-button color="primary"  style="float:right;margin-top: -25px;" [disabled]=!this._global.admin ><span *ngIf="!deltaButtonName">Save</span><span *ngIf="deltaButtonName"  >Update</span></button>
        </td>
      </tr>
    </table>
  </form>
  </mat-tab>
</mat-tab-group>



