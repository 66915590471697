
<h1 style="text-align:center;color:#3f51b5" >{{formTitle}}</h1>

 <!-- Column Config Form Starts -->
<form [formGroup]="columnConfigFormGroup" (ngSubmit)="columnConfigSubmit(columnConfigFormGroup)" *ngIf="isColumnConfig">

  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (valueChange)="onPlantSelect('columnconfig')" formControlName="plantControl" required [compareWith]="compareFn" >
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{columnConfigFormGroup.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>

  <div class="parent"  >
    <!-- <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="plant_id">
    <input type="hidden" formControlName="project_id"> -->
    <table class="lineTable"> 
    
      <tr>
        <td >
          <mat-form-field class="demo-full-width" appearance="outline"  style="width: 95% !important;">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput placeholder="Object" required pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)">
            <mat-hint style="margin-left: -10px;">Name supports A-Z,a-z,0-9</mat-hint>
          </mat-form-field>
          <mat-error>
             <!-- <span *ngIf="!columnConfigFormGroup.get('name').valid && columnConfigFormGroup.get('name').touched">Please enter name !!!</span> 
             -->
             <span *ngIf="columnConfigFormGroup.get('name').touched && !columnConfigFormGroup.get('name').dirty && !columnConfigFormGroup.get('name').valid">Please enter name !!!</span> 
             <span *ngIf="columnConfigFormGroup.get('name').dirty && !columnConfigFormGroup.get('name').valid">Please enter valid name !!!</span>
          
            </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Order</mat-label>
            <input type="number" formControlName="order" matInput placeholder="Display Order">
          </mat-form-field>
          <mat-error>
            <!-- <span *ngIf="!columnConfigFormGroup.get('order').valid && columnConfigFormGroup.get('order').touched">Please enter Display Order !!!</span>  -->
          </mat-error>
        </td>


      </tr>
      
      <tr>
        <td colspan="2">
          <mat-form-field class="demo-full-width" appearance="outline" style="width: 95% !important;">
            <mat-label>Description</mat-label>
            <input formControlName="description" matInput placeholder="Description" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!columnConfigFormGroup.get('description').valid && columnConfigFormGroup.get('description').touched">Please enter Description !!!</span> 
          </mat-error>
        </td>
      </tr>

      <div style="width:100%">
        <button mat-raised-button color="primary" [disabled]="!columnConfigFormGroup.valid" style="float:left" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!columnConfigFormGroup.valid" style="margin-left:1.5%" *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" routerLink="/subassemblydelta" type="reset" style="margin-left:2%">Cancel</button>
  
      </div>
    
    </table>


  </div>

</form>

 <!-- Column Config Form Ends -->

  <!-- Value Config Form Starts -->

 <form [formGroup]="valueConfigFormGroup" (ngSubmit)="valueConfigSubmit(valueConfigFormGroup)" *ngIf="isValueConfig" >

  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (valueChange)="onPlantSelect('valueconfig')" formControlName="plantControl" required [compareWith]="compareFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{valueConfigFormGroup.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>

  <div class="parent" >

    <table class="lineTable">
      
      <tr>
        <td >
          <mat-form-field class="demo-full-width" appearance="outline"  style="width: 95% !important;">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput placeholder="Name" required pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)">
            <mat-hint style="margin-left: -10px;">Name supports A-Z,a-z,0-9</mat-hint>
          </mat-form-field>
          <mat-error>
             <!-- <span *ngIf="!valueConfigFormGroup.get('name').valid && valueConfigFormGroup.get('name').touched">Please enter name !!!</span>  -->
             <span *ngIf="valueConfigFormGroup.get('name').touched && !valueConfigFormGroup.get('name').dirty && !valueConfigFormGroup.get('name').valid">Please enter name !!!</span> 
             <span *ngIf="valueConfigFormGroup.get('name').dirty && !valueConfigFormGroup.get('name').valid">Please enter valid name !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Name</mat-label>
            <input formControlName="display_name" matInput placeholder="Display Name" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!valueConfigFormGroup.get('display_name').valid && valueConfigFormGroup.get('display_name').touched">Please enter Display Name !!!</span> 
          </mat-error>
        </td>
      </tr>

      <tr>
        <td >
          <mat-form-field class="demo-full-width" appearance="outline"  style="width: 95% !important;">
            <mat-label>Display Order</mat-label>
            <input type="number" formControlName="display_order" matInput placeholder="Disply Order">
          </mat-form-field>
          <mat-error>
             <span *ngIf="!valueConfigFormGroup.get('display_order').valid && valueConfigFormGroup.get('display_order').touched">Please display Order !!!</span> 
          </mat-error>
        </td>
        <td *ngIf="fullColumnData[0]">
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>{{fullColumnData[0].name}}</mat-label>
            <input formControlName="C1Value" matInput placeholder="C1 Value" >
          </mat-form-field>
          <mat-error>
            <!-- <span *ngIf="!valueConfigFormGroup.get('display_name').valid && valueConfigFormGroup.get('display_name').touched">Please enter C1 Value !!!</span>  -->
          </mat-error>
        </td>


      </tr>

       <tr>
         <td *ngIf="fullColumnData[1]">
          <mat-form-field class="demo-full-width" appearance="outline" style="width: 95% !important;">
            <mat-label>{{fullColumnData[1].name}}</mat-label>
            <input formControlName="C2Value" matInput placeholder="C2 Value" >
          </mat-form-field>
         </td>

         <td *ngIf="fullColumnData[2]">
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>{{fullColumnData[2].name}}</mat-label>
            <input formControlName="C3Value" matInput placeholder="C3 Value" >
          </mat-form-field>
        </td>

       </tr>

       <tr>
        <td *ngIf="fullColumnData[3]">
         <mat-form-field class="demo-full-width" appearance="outline" style="width: 95% !important;">
           <mat-label>{{fullColumnData[3].name}}</mat-label>
           <input formControlName="C4Value" matInput placeholder="C4 Value" >
         </mat-form-field>
        </td>

        <td *ngIf="fullColumnData[4]">
         <mat-form-field class="demo-full-width" appearance="outline">
           <mat-label>{{fullColumnData[4].name}}</mat-label>
           <input formControlName="C5Value" matInput placeholder="C5 Value" >
         </mat-form-field>
       </td>

      </tr>

      <tr>
      <td>
        <mat-checkbox class="padding_right" [checked]="isRollup" (change)="onRollupChange($event)" style="font-size: 14pt;">Roll Up</mat-checkbox> 
      </td>
      <td></td>
      </tr>
    
     
      <p></p>
      <div style="width:100%">
        <button mat-raised-button color="primary" [disabled]="!valueConfigFormGroup.valid" style="float:left" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!valueConfigFormGroup.valid" style="margin-left:1.5%" *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" routerLink="/subassemblydelta" type="reset" style="margin-left:2%">Cancel</button>
  
      </div>
    
    </table>


  </div>
 </form>
