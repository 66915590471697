<form [formGroup]="plantControl">
  <mat-form-field class="slicer">
    <mat-label>Plant</mat-label>
    <mat-select (selectionChange)="onPlantSelect()" formControlName="plantControl" [compareWith]="compareFn">
      <mat-option value="null" disabled selected>Select...</mat-option>
      <mat-option *ngFor="let plant of plantService.list" [value]="plant">
        {{plant.name}}
      </mat-option>
    </mat-select>
    <mat-hint>{{plantControl.get('plantControl').value?.display_name}}</mat-hint>
  </mat-form-field>
  <a mat-raised-button color="primary" class="newButton" (click)="createLine()" [disabled]=!this._global.admin >Add New Line</a>
</form>



<div class="table-padding">
  <table class="lineTable">
    <tr>
      <th width="13.7%">Name</th>
      <th width="13.7%">Status</th>
      <th width="13.7%">Target</th>
      <th width="13.7%">Starts</th>
      <th width="13.7%">packed</th>
      <th width="13.7%">Display Order</th>
      <th width="13.7%">Agregate</th>
    </tr>
    <tr *ngFor="let line of lineService.list" style="cursor:pointer">
      <td width="13.7%" (click)="onLineSelect(line)">{{line.name}}</td>
      <td width="13.7%" (click)="onLineSelect(line)"> {{line.status}}</td>
      <td width="13.7%" (click)="onLineSelect(line)">{{line.target}}</td>
      <td width="13.7%" (click)="onLineSelect(line)">{{line.starts}}</td>
      <td width="13.7%" (click)="onLineSelect(line)">{{line.packed}}</td>
      <td width="13.7%" (click)="onLineSelect(line)">{{line.display_order}}</td>
      <td width="13.7%" (click)="onLineSelect(line)">{{line.agregate}}</td>
      <td width="5%"><button mat-raised-button (click)="openDialog(line)" color="warn" [disabled]=!this._global.admin >X</button></td>
    </tr>
  </table>
</div>
