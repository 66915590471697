<h1 style="text-align:center;color:#3f51b5" *ngIf="type">Create Plant</h1>
<h1 style="text-align:center;color:#3f51b5" *ngIf="!type">Update Plant</h1>

<form [formGroup]="formGroup" (ngSubmit)="onFormSubmit(formGroup)">
  <div class="parent">
    <input type="hidden" formControlName="id">
    <table class="plantTable">
      
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Plant Name</mat-label>
            <input formControlName="name" matInput placeholder="Plant Name" required pattern="^[A-Za-z0-9 ]*$" (keypress)="plantService.restrictSpecialCharachters($event)">
            <mat-hint style="margin-left: -10px;">Plant Name supports A-Z,a-z,0-9</mat-hint>
          </mat-form-field>
          <mat-error>
            <!-- <span *ngIf="!formGroup.get('name').valid && formGroup.get('name').touched">Please enter Plant Name !!!</span> -->
            <span *ngIf="formGroup.get('name').touched && !formGroup.get('name').dirty && !formGroup.get('name').valid">Please enter plant name !!!</span> 
            <span *ngIf="formGroup.get('name').dirty && !formGroup.get('name').valid">Please enter valid plant name !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Name</mat-label>
            <input formControlName="display_name" matInput placeholder="Display Name" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('display_name').valid && formGroup.get('display_name').touched">Please enter Display Name !!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>SCADA Project</mat-label>
            <input formControlName="project_sc" matInput placeholder="SCADA Project" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('project_sc').valid && formGroup.get('project_sc').touched">Please enter SCADA Project !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Tracker Project</mat-label>
            <input formControlName="project_tr" matInput placeholder="Tracker Project" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('project_tr').valid && formGroup.get('project_tr').touched">Please enter Tracker Project !!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Environment</mat-label>
            <input formControlName="Environment" matInput placeholder="Environment" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('Environment').valid && formGroup.get('Environment').touched">Please enter Environment !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Timezone</mat-label>
            <input formControlName="time_zone" matInput placeholder="Timezone" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('time_zone').valid && formGroup.get('time_zone').touched">Please enter Timezone!!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Microsoft SQL Connection</mat-label>
            <input formControlName="MSSQLConnection" matInput placeholder="Microsoft SQL Connection">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('MSSQLConnection').valid && formGroup.get('MSSQLConnection').touched">Please enter Microsift SQL Connection !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>OPC Connection</mat-label>
            <input formControlName="OPCConnection" matInput placeholder="OPC Connection" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('OPCConnection').valid && formGroup.get('OPCConnection').touched">Please enter OPC Connection !!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Tag Provider</mat-label>
            <input formControlName="tagProvider" matInput placeholder="Tag Provider" required>
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('tagProvider').valid && formGroup.get('tagProvider').touched">Please enter Tag Provider !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Site</mat-label>
            <input formControlName="Site" matInput placeholder="Site">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('Site').valid && formGroup.get('Site').touched">Please enter Site!!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>
          <mat-checkbox formControlName="auto_create">Auto Create</mat-checkbox>
          <mat-error>
            <span *ngIf="!formGroup.get('auto_create').valid && formGroup.get('auto_create').touched">Please enter Auto Create !!!</span>
          </mat-error>
        </td>
        <td>
          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Type</mat-label>
            <input formControlName="Type" matInput placeholder="Type">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('Type').valid && formGroup.get('Type').touched">Please enter Type!!!</span>
          </mat-error>
        </td>
      </tr>
      <tr>
        <td>

          <mat-form-field class="demo-full-width" appearance="outline">
            <mat-label>Display Order</mat-label>
            <input formControlName="display_order" matInput placeholder="Display Order">
          </mat-form-field>
          <mat-error>
            <span *ngIf="!formGroup.get('display_order').valid && formGroup.get('display_order').touched">Please enter Display Order !!!</span>
          </mat-error>

        </td>
        <td></td>
      </tr>
      <div style="width:100%">
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="float:left" *ngIf="type">Save</button>
        <button mat-raised-button color="primary" [disabled]="!formGroup.valid" style="margin-left:1.5%" *ngIf="!type">Update</button>
        <button mat-raised-button color="primary" routerLink="/plants" type="reset" style="margin-left:2%">Cancel</button>
      </div>
    </table>
  </div>
</form>