import { Component, OnInit } from '@angular/core';
import { LineService } from '../line.service';
import { UntypedFormGroup, UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { PlantService } from '../../plant.service';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Line } from '../line.model';
import { Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-line-manger',
  templateUrl: './line-manger.component.html',
  styleUrls: ['./line-manger.component.css']
})
export class LineMangerComponent implements OnInit {

  formGroup: UntypedFormGroup;
  id: number;
  type: boolean = true;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
  //compareprojectFn: ((f1: any, f2: any) => boolean) | null = this.compareprojectFnValue;
  
  
  lineData: any;
  splant:any =[];
  Selectline:any=[{}];
  projectlist: any;
  plantId:any;
  isAggregate:boolean=false;
  matcher=new MyErrorStateMatcher();

  constructor(private formBuilder: UntypedFormBuilder,
    public plantService: PlantService,
    private lineService: LineService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router:Router) {

    //Initialize form
    this.formGroup = this.formBuilder.group({
      id: [],
      name: [],
      plant_id: [],
      status: [],
      target: [],
      starts: [],
      packed: [],
      display_order: [],
      schedule_area: [],
      test_line: [],
      test_station: [],
      test_area: [],
      test_code: [],
      LineCode: [],
      SchemaName: [],
      plantControl: [],
      projectControl:[],
      projectControlUpdate:[],
      eola_schema:[],
      eola_line:[],
      agregate:[],
      fpy_quality_threshold: ["", Validators.required],
      eola_quality_threshold: ["", Validators.required],
      Description:[],
      test_yield_threshold: ["", Validators.required],
      asset_name: []
    });

  }

  ngOnInit() {
    this.route.data.subscribe(v => {
      if (v.type == 'create') {
        this.type = true;
        this.formGroup.get('plantControl').enable();
      } else {
        this.type = false;
        this.formGroup.get('plantControl').disable();
      }

    });
    //set selected plant
    if (this.plantService.selectedPlant != undefined) {
      this.plantService.aSynchRefreshList().subscribe(data => {
        this.formGroup.get('plantControl').setValue(this.plantService.selectedPlant);
        this.formGroup.get('plant_id').setValue(this.plantService.selectedPlant.id);
      });
    } else {
      this.plantService.refreshList();
    }
    //set selected line
    if (this.lineService.selectedline != undefined) {
      this.formGroup.patchValue(this.lineService.selectedline);
      if(this.lineService.selectedline.agregate){
        this.isAggregate=true;
       }
      this.lineService.selectedline = null;
    }

    if (this.plantService.selectedPlant != undefined) {

      let plantSelcted=this.formGroup.get('plant_id').value;
      this.splant=this.plantService.plantSeclet;
      this.plantService.refreshProjectList(this.splant).subscribe(data => {
      this.lineData=data;
      this.Selectline= this.lineData.find(e => e.id ==  plantSelcted);
      if(this.Selectline != undefined){
          let finaltext=this.Selectline.name+"-"+this.Selectline.Environment;
          this.formGroup.get('projectControlUpdate').setValue(finaltext);
          this.plantId=this.Selectline.id;
      }

      })
      this.plantService.refreshProjectList(this.splant).subscribe(data => {
        this.projectlist=data;
      });

      }

  }

  onPlantSelect() {
    this.formGroup.get('plant_id').setValue(this.formGroup.get('plantControl').value.id);
    this.plantService.setSelectedPlant(this.formGroup.get('plantControl').value);

    let plantSelcted=this.formGroup.get('plantControl').value.name;
    this.plantService.refreshProjectList(plantSelcted).subscribe(data => {
      this.projectlist=data;
    });
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.name === f2.name;
  }
  // compareprojectFnValue(f1: any, f2: any) {
  //   return f1 && f2 && f1.name === f2.name;
  // }
  onFormSubmit() {
    if (this.formGroup.getRawValue().id == null)
      this.insertRecord(this.formGroup.getRawValue());
    else
      this.updateRecord(this.formGroup.getRawValue());
  }

  insertRecord(form: Line) {
    form.plant_id=this.formGroup.getRawValue().projectControl.id;
    if(this.isAggregate){
      form.agregate=1;
    }else{
      form.agregate=0;
    }
    this.lineService.postLine(form).subscribe(res => {
      this._snackBar.open("Record creation Success", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
     this.router.navigate(["/lines"]);
   });
  }

  updateRecord(form: Line) {
    form.plant_id=this.plantId;
    if(this.isAggregate){
      form.agregate=1;
    }else{
      form.agregate=0;
    }
    this.lineService.putLine(form).subscribe(res => {
       this._snackBar.open("Record Updation Success", "", {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['alert-success'],
      });
      this.router.navigate(["/lines"]);
    });

  }

  onAggregateChange(event){
    if(event.checked){
      this.isAggregate=true;
    }else{
      this.isAggregate=false;
    }
  }
  restrictSpecialCharachters(evt: any): boolean{
     let regEx=/^[0-9]*$/
     if (regEx.test(evt.key)) {
      return true;
     } else {
      return false;   
     }
  }


}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid);
  }
}
